<template>
  <div class="imelink-reviewview" ref="wrap">
    <respon-notice
      v-if="control.noticeViewState"
      @refresh="control._changeMessageView(control.currentContact)"
      :type="control.noticeViewState"
    />
    <template v-else>
      <!-- 刷新 -->
      <div
        class="refreshExamine"
        v-if="
          $store.state.examine.hasNewMessage &&
            $store.state.imStore.IMWindowSize != 'mini'
        "
      >
        {{ $t("haveNewReview") }}
        <span @click="refreshExamine">{{ $t("refresh") }}</span>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="first" :class="leftData.length > 2 ? 'pd66' : ''">
          <span slot="label">
            <i class="iconfont_Me icon-a-zu10157"></i>
          </span>
          <div class="scroll-wrap" ref="scrollWrap1">
            <el-checkbox-group
              v-model="checkedAditList"
              @change="handleCheckedCitiesChange"
              v-if="leftData.length > 0 || isShowLoadMore"
            >
              <div class="item" v-for="(adit, inx) in leftData" :key="inx">
                <!-- 帖子 -->
                <div v-if="adit.targetType == 1">
                  <div class="top">
                    <el-checkbox :label="adit"></el-checkbox>
                    <div class="head">
                      <el-image
                        :src="adit.targetData.profilePhoto"
                        :preview-src-list="[adit.targetData.profilePhoto]"
                      ></el-image>
                    </div>
                    <span v-if="adit.targetData.vipStatus" class="xvipName">
                      <span
                        class="vipDisplayName"
                        @click.stop="goPersonalCenter(adit.targetData.id)"
                        >{{ adit.targetData.name }}</span
                      >
                      <el-tooltip
                        effect="dark"
                        :content="$t('paidMember')"
                        placement="bottom"
                        popper-class="me-tooltip-effect"
                      >
                        <el-image
                          class="vipIcon"
                          :src="require('@/assets/images/pay/masonry.png')"
                          fit="fill"
                          @click.stop="handleClickVip(adit.targetData.id)"
                        >
                        </el-image>
                      </el-tooltip>
                    </span>
                    <template v-else>
                      <div
                        class="name"
                        @click.stop="goPersonalCenter(adit.targetData.id)"
                      >
                        {{ adit.targetData.name }}
                      </div>
                    </template>
                  </div>
                  <div class="title cont" v-if="adit.targetData.iCanDo">
                    {{ adit.targetData.iCanDo }}
                  </div>
                  <div
                    class="cont"
                    v-if="
                      adit.targetData.detail && adit.targetData.detail != '[]'
                    "
                  >
                    {{
                      JSON.parse(adit.targetData.detail)[0].content ||
                        adit.targetData.detail
                    }}
                  </div>
                </div>
                <!-- 评论 -->
                <div
                  v-if="
                    adit.targetType == 11 ||
                      adit.targetType == 31 ||
                      adit.targetType == 41
                  "
                >
                  <div class="top">
                    <el-checkbox :label="adit"></el-checkbox>
                    <div class="head">
                      <el-image
                        :src="adit.targetData.userAvatar"
                        :preview-src-list="[adit.targetData.userAvatar]"
                      ></el-image>
                    </div>
                    <span v-if="adit.targetData.vipStatus" class="xvipName">
                      <span
                        class="vipDisplayName"
                        @click.stop="goPersonalCenter(adit.targetData.id)"
                        >{{ adit.targetData.name }}</span
                      >
                      <el-tooltip
                        effect="dark"
                        :content="$t('paidMember')"
                        placement="bottom"
                        popper-class="me-tooltip-effect"
                      >
                        <el-image
                          class="vipIcon"
                          :src="require('@/assets/images/pay/masonry.png')"
                          fit="fill"
                          @click.stop="handleClickVip(adit.targetData.id)"
                        >
                        </el-image>
                      </el-tooltip>
                    </span>
                    <template v-else>
                      <div
                        class="name"
                        @click.stop="goPersonalCenter(adit.targetData.id)"
                      >
                        {{ adit.targetData.name }}
                      </div>
                    </template>
                  </div>

                  <div
                    class="cont"
                    v-html="
                      $emoReplaceToImage(reconvert(adit.targetData.content))
                    "
                  ></div>
                  <div class="image" v-if="adit.targetData.imgUrl != ''">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="adit.targetData.imgUrl"
                      fit="cover"
                      @click.stop="showImage(adit.targetData.imgUrl)"
                    >
                    </el-image>
                  </div>
                </div>
                <!-- 用户 -->
                <div v-if="adit.targetType == 3">
                  <div class="top">
                    <el-checkbox :label="adit"></el-checkbox>
                    <div class="head">
                      <el-image
                        v-if="adit.targetData.profilePhoto"
                        :src="adit.targetData.profilePhoto"
                        :preview-src-list="[adit.targetData.profilePhoto]"
                      ></el-image>
                    </div>
                    <span v-if="adit.targetData.vipStatus" class="xvipName">
                      <span
                        class="vipDisplayName"
                        @click.stop="goPersonalCenter(adit.targetData.id)"
                        >{{ adit.targetData.name }}</span
                      >
                      <el-tooltip
                        effect="dark"
                        :content="$t('paidMember')"
                        placement="bottom"
                        popper-class="me-tooltip-effect"
                      >
                        <el-image
                          class="vipIcon"
                          :src="require('@/assets/images/pay/masonry.png')"
                          fit="fill"
                          @click.stop="handleClickVip(adit.targetData.id)"
                        >
                        </el-image>
                      </el-tooltip>
                    </span>
                    <template v-else>
                      <div
                        class="name"
                        @click.stop="goPersonalCenter(adit.targetData.id)"
                      >
                        {{ adit.targetData.name }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="loadMoreWrap" v-if="isShowLoadMore">
                <div class="loadMore" @click="loadMore">
                  {{ this.$t("loadMore") }}
                </div>
              </div>
            </el-checkbox-group>
            <div v-else class="noData">
              <img
                src="../../../../assets/images/class/no-data-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="operation" v-if="leftData.length > 0">
            <div style="display:flex">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                style="margin-right:6px"
              ></el-checkbox>
              <div v-show="checkedAditList.length > 0">
                {{ checkedAditList.length }}
              </div>
            </div>
            <div class="right">
              <div class="btn" @click="handle_delete">
                <i class="iconfont_Me icon-delet"></i>
              </div>
              <div class="btn" @click="handle_pass">
                <i class="iconfont_Me icon-check"></i>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span slot="label" name="second"
            ><i class="iconfont_Me icon-Post-fill"></i
          ></span>
          <div
            class="scroll-wrap"
            ref="scrollWrap2"
            v-if="rightData.length > 0 || isShowLoadMore"
          >
            <el-checkbox-group
              v-model="checkedRightData"
              @change="handleCheckedRightChange"
              v-if="rightData.length > 0 || isShowLoadMore"
            >
              <div
                class="item rightBoxItem"
                v-for="adit in rightData"
                :key="adit.id"
              >
                <div class="checkbox">
                  <el-checkbox :label="adit"></el-checkbox>
                </div>
                <div class="itemContent" @click.stop="toDetials(adit)">
                  <div class="top" v-if="adit.targetType != 4">
                    <div class="head">
                      <el-image
                        v-if="adit.targetData.profilePhoto"
                        :src="adit.targetData.profilePhoto"
                      >
                      </el-image>
                    </div>
                    <span v-if="adit.targetData.vipStatus" class="xvipName">
                      <span
                        class="vipDisplayName"
                        @click.stop="
                          goPersonalCenter(adit.targetData.providerId)
                        "
                        >{{ adit.targetData.name }}</span
                      >
                      <el-tooltip
                        effect="dark"
                        :content="$t('paidMember')"
                        placement="bottom"
                        popper-class="me-tooltip-effect"
                      >
                        <el-image
                          class="vipIcon"
                          :src="require('@/assets/images/pay/masonry.png')"
                          fit="fill"
                          @click.stop="
                            handleClickVip(adit.targetData.providerId)
                          "
                        >
                        </el-image>
                      </el-tooltip>
                    </span>
                    <template v-else>
                      <div
                        class="name"
                        @click.stop="
                          goPersonalCenter(adit.targetData.providerId)
                        "
                      >
                        {{ adit.targetData.name }}
                      </div>
                    </template>
                    <div class="operat">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                  <template
                    v-if="adit.targetType === 3 && adit.targetData.intro"
                  >
                    <div
                      class="user"
                      v-if="JSON.parse(adit.targetData.intro)[0]"
                    >
                      <div
                        class="video"
                        v-if="
                          JSON.parse(adit.targetData.intro)[0].key == 'video'
                        "
                      >
                        <img
                          :src="
                            JSON.parse(adit.targetData.intro)[0].content +
                              '?vframe/jpg/offset/0'
                          "
                          alt=""
                        />
                        <div class="videoStatus">
                          <span
                            class="iconfont_Me icon-play2-fill videoStatusIconStyle"
                          ></span>
                        </div>
                      </div>
                      <img
                        class="img"
                        v-if="
                          JSON.parse(adit.targetData.intro)[0].key === 'image'
                        "
                        :src="JSON.parse(adit.targetData.intro)[0].content"
                      />
                      <div
                        v-if="
                          JSON.parse(adit.targetData.intro)[0].key === 'text'
                        "
                        class="cont"
                      >
                        {{ JSON.parse(adit.targetData.intro)[0].content }}
                      </div>
                      <div
                        v-if="
                          JSON.parse(adit.targetData.intro)[0].key === 'title'
                        "
                        class="title"
                      >
                        {{ JSON.parse(adit.targetData.intro)[0].content }}
                      </div>
                    </div>
                  </template>

                  <!-- 群 -->
                  <template v-if="adit.targetType == 4">
                    <div class="group">
                      <div class="ghead">
                        <!-- <img :src="adit.targetData.groupHeader" alt=""> -->
                        <el-image
                          :src="adit.targetData.groupHeader"
                          fit="cover"
                        ></el-image>
                      </div>
                      <div class="gname">
                        {{ adit.targetData.groupName }}
                      </div>
                      <div class="operat">
                        <i class="el-icon-arrow-right"></i>
                      </div>
                    </div>
                  </template>
                  <!-- 标题 -->
                  <div class="title cont" v-if="adit.targetData.iCanDo">
                    {{ adit.targetData.iCanDo }}
                  </div>
                  <!-- 帖子 -->
                  <template
                    v-if="
                      adit.targetData.detail && adit.targetData.detail != '[]'
                    "
                  >
                    <!-- 内容 -->
                    <div
                      class="cont"
                      v-if="JSON.parse(adit.targetData.detail)[0].key == 'text'"
                    >
                      {{ JSON.parse(adit.targetData.detail)[0].content }}
                    </div>
                    <!-- 图片 -->
                    <div
                      class="cont"
                      v-if="
                        JSON.parse(adit.targetData.detail)[0].key == 'image' &&
                          (!adit.targetData.attachments ||
                            adit.targetData.attachments.length == 0)
                      "
                    >
                      <img
                        :src="JSON.parse(adit.targetData.detail)[0].content"
                        style="width:110px;height: 100px; object-fit: cover;border-radius: 3px;"
                        alt=""
                      />
                    </div>
                    <!-- 富文本中的视频 -->
                    <div
                      class="cont video"
                      v-if="
                        JSON.parse(adit.targetData.detail)[0].key == 'video' &&
                          (!adit.targetData.attachments ||
                            adit.targetData.attachments.length == 0)
                      "
                    >
                      <img
                        :src="
                          JSON.parse(adit.targetData.detail)[0].content +
                            '?vframe/jpg/offset/0'
                        "
                        alt=""
                      />
                      <div class="videoStatus">
                        <span
                          class="iconfont_Me icon-play2-fill videoStatusIconStyle"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="cont"
                      v-if="
                        JSON.parse(adit.targetData.detail)[0].key == 'web' &&
                          (!adit.targetData.attachments ||
                            adit.targetData.attachments.length == 0)
                      "
                    >
                      <div class="_a" target="_black">
                        <div class="webImag">
                          <img
                            :src="
                              JSON.parse(adit.targetData.detail)[0].content
                                .image
                            "
                            alt=""
                          />
                        </div>
                        <div class="webInfo">
                          <div class="webTitle">
                            {{
                              JSON.parse(adit.targetData.detail)[0].content
                                .title
                            }}
                          </div>
                          <div class="webCont">
                            {{
                              JSON.parse(adit.targetData.detail)[0].content
                                .content
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="cont url"
                      v-if="
                        JSON.parse(adit.targetData.detail)[0].key == 'url' &&
                          (!adit.targetData.attachments ||
                            adit.targetData.attachments.length == 0)
                      "
                    >
                      <img
                        data-v-9d3a79a6=""
                        src="https://tfile.melinked.com/2021/03/c2856521-972f-4b9f-9df5-2abc1ce6a384.png"
                        style="width: 16px; height: 16px; margin-right: 5px;"
                      />
                      <a style="color:rgb(34, 108, 219);font-size:14px;"
                        >{{
                          JSON.parse(
                            JSON.parse(adit.targetData.detail)[0].content
                          ).showLink
                        }}
                      </a>
                    </div>
                  </template>
                  <!-- 附件部分 -->
                  <template
                    v-if="
                      adit.targetData.attachments &&
                        adit.targetData.attachments.length > 0
                    "
                  >
                    <!-- type 3 图片 -->
                    <div
                      class="att"
                      v-if="adit.targetData.attachments[0].type == 3"
                    >
                      <img
                        :src="adit.targetData.attachments[0].path"
                        style="width:110px; height: 100px; object-fit: cover; border-radius: 3px;"
                        alt=""
                      />
                    </div>
                    <!-- type 4 文件 -->
                    <div
                      class="att"
                      v-if="adit.targetData.attachments[0].type == 4"
                    >
                      <div class="file-box">
                        <div class="prixImg">
                          <el-image
                            class="fileIcon"
                            :src="
                              formartNewFileIcon(
                                nameSfx(adit.targetData.attachments[0].name)
                              )
                            "
                          />
                        </div>
                        <div
                          style="color:#373737;font-weight: bold;font-size: 14px;"
                        >
                          {{ adit.targetData.attachments[0].name }}
                        </div>
                      </div>
                    </div>
                    <!-- type 1 视频 -->
                    <div
                      v-if="adit.targetData.attachments[0].type == 1"
                      class="video"
                    >
                      <img
                        :src="
                          adit.targetData.attachments[0].path +
                            '?vframe/jpg/offset/0'
                        "
                        alt=""
                      />
                      <div class="videoStatus">
                        <span
                          class="iconfont_Me icon-play2-fill videoStatusIconStyle"
                        ></span>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="
                      adit.targetData.detail &&
                        JSON.parse(adit.targetData.detail).length > 1
                    "
                  >
                    ...
                  </div>
                </div>
              </div>
              <div class="loadMoreWrap" v-if="isShowLoadMore">
                <div class="loadMore" @click="loadMore">
                  {{ this.$t("loadMore") }}
                </div>
              </div>
            </el-checkbox-group>
          </div>
          <div class="operation" v-if="rightData.length > 0">
            <div style="display:flex">
              <el-checkbox
                :indeterminate="rightIsIndeterminate"
                v-model="checkRightAll"
                @change="handleCheckRightAllChange"
                style="margin-right:6px"
              ></el-checkbox>
              <div v-show="checkedRightData.length > 0">
                {{ checkedRightData.length }}
              </div>
            </div>
            <div class="right">
              <div class="btn" @click="handle_right_delete">
                <i class="iconfont_Me icon-delet"></i>
              </div>
              <div class="btn" @click="handle_right_pass">
                <i class="iconfont_Me icon-check"></i>
              </div>
            </div>
          </div>
          <div v-else class="noData">
            <img src="../../../../assets/images/class/no-data-img.png" alt="" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import { auditList, audit } from "@/api/newVersion/audit";
import { reconvert } from "@/utils";
import { mapMutations } from "vuex";
import * as RESTApi from "@/api/rest";
export default {
  name: "Reviewview",
  inject: ["control"],
  data() {
    return {
      notices: [],
      initPage: true,
      height: 0,
      activeName: "first",
      isIndeterminate: true,
      rightIsIndeterminate: true,
      checkAll: true,
      checkRightAll: true,
      page: 1,
      pageSize: 20,
      pages: 0,
      aditList: [],
      checkedAditList: [],
      leftData: [],
      rightData: [],
      checkedRightData: [],
      isShowLoadMore: true,
      userVipStatusList: {},
    };
  },
  watch: {
    "$store.state.examine.needUpdateAuditList": {
      handler(val, old) {
        if (val && val !== old) {
          this.checkedAditList = [];
          this.checkedRightData = [];
          this.aditList = [];
          this.page = 1;
          this.getAditList();
        }
      },
    },
  },
  mounted() {
    this.getAditList();
  },
  methods: {
    ...mapMutations([
      "setExaminePopoverVisible",
      "setExamineId",
      "setExamineData",
      "setDeleteType",
      "setMultipleData",
      "setDeleteDialogVisible",
      "setHasNewMessage",
    ]),
    goPersonalCenter(id) {
      this.routeJump({
        name: "personalCenter",
        params: { id: id },
      });
    },
    showImage(imgUrl) {
      let multimediaPopupConfig = JSON.parse(
        JSON.stringify(this.$store.state.homePage.multimediaPopupConfig)
      );
      this.$set(multimediaPopupConfig, "pictureCollection", [imgUrl]);
      this.$set(multimediaPopupConfig, "pictureIndex", 0);
      this.$store.dispatch(
        "commitMultimediaPopupConfig",
        multimediaPopupConfig
      );
    },
    reconvert(msg) {
      return reconvert(msg);
    },
    nameSfx(name) {
      if (!name) return "";
      const arr = name.split(".");
      let str = arr[arr.length - 1].toUpperCase();
      if (str.length > 3) {
        str = str.substring(0, 3);
      }
      return str;
    },
    async getAditList() {
      let params = {
        auditStatus: 0,
        page: this.page,
        pageSize: this.pageSize,
      };
      let result = await auditList(params);
      if (result.code == 200) {
        this.setHasNewMessage(false);
        // 这里去处理一下数据，帖子纯文字分在做左边的少数据。 其余为多数据。
        this.pages = result.data.pages;
        this.page >= this.pages && (this.isShowLoadMore = false);
        this.aditList = this.aditList
          ? this.aditList.concat(result.data.data)
          : result.data.data;
        this.handleAditList(this.aditList);
      }
    },

    handleClick() {},
    handleCheckAllChange(val) {
      this.checkedAditList = val ? this.leftData : [];
      this.isIndeterminate = false;
    },
    handleCheckRightAllChange(val) {
      this.checkedRightData = val ? this.rightData : [];
      this.rightIsIndeterminate = false;
    },
    handle_right_delete() {
      if (this.checkedRightData.length == 0) {
        this.$message({
          message: this.$t("notSelectedData"),
          type: "error",
        });
      } else {
        this.$store.commit("setNeedUpdateAuditList", false);
        this.setDeleteType("multiple");
        this.setMultipleData(this.checkedRightData);
        this.setDeleteDialogVisible(true);
      }
    },
    handle_delete() {
      if (this.checkedAditList.length == 0) {
        this.$message({
          message: this.$t("notSelectedData"),
          type: "error",
        });
      } else {
        this.$store.commit("setNeedUpdateAuditList", false);
        this.setDeleteType("multiple");
        this.setMultipleData(this.checkedAditList);
        this.setDeleteDialogVisible(true);
      }
    },
    handle_right_pass() {
      if (this.checkedRightData.length == 0) {
        this.$message({
          message: this.$t("notSelectedData"),
          type: "error",
        });
      } else {
        this.confirm(
          null,
          this.$t("isApproval"),
          {
            type: "none",
          },
          this.passRightPost
        );
      }
    },
    handle_pass() {
      if (this.checkedAditList.length == 0) {
        this.$message({
          message: this.$t("notSelectedData"),
          type: "error",
        });
      } else {
        this.confirm(
          null,
          this.$t("isApproval"),
          {
            type: "none",
          },
          this.passPost
        );
      }
    },
    async passRightPost() {
      let ids = [];
      this.checkedRightData.map((item) => {
        ids.push(item.id);
      });
      let params = { auditIds: ids.join(","), auditStatus: 2 };
      let result = await audit(params);
      if (result.code == 200) {
        this.$message({
          message: "success",
          type: "success",
        });
        this.checkedRightData = [];
        this.aditList = [];
        this.getAditList();
      } else {
        this.$message({
          message: result.message,
          type: "error",
        });
      }
    },
    async passPost() {
      let ids = [];
      this.checkedAditList.map((item) => {
        ids.push(item.id);
      });
      let params = { auditIds: ids.join(","), auditStatus: 2 };
      let result = await audit(params);
      if (result.code == 200) {
        this.$message({
          message: "success",
          type: "success",
        });
        this.checkedAditList = [];
        this.aditList = [];
        this.getAditList();
      } else {
        this.$message({
          message: result.message,
          type: "error",
        });
      }
    },
    refreshExamine() {
      this.page = 1;
      this.aditList = null;
      this.getAditList();
      this.scrollToTop();
    },
    async getUserInfo(id) {
      if (!this.userVipStatusList[id]) {
        const info = await RESTApi.getProfileInfoData({
          providerId: id,
        });
        this.userVipStatusList[id] = info.data[0].vipStatus || false;
      }
      return this.userVipStatusList[id];
    },
    async handleAditList(data) {
      //targetType  1：帖子；11：帖子评论；3：个人；31：个人评论；4：群；41：群评论
      let leftData = []; //少数据
      let rightData = []; //多数据
      data.map((item) => {
        //评论类型，暂时判断为少数据类型
        if (
          item.targetType == 11 ||
          item.targetType == 31 ||
          item.targetType == 41
        ) {
          leftData.push(item);
        } else if (item.targetType == 1) {
          //帖子类型
          let attachments = item.targetData.attachments;
          let tags = item.targetData.tags;
          let jsonDetail = null;
          try {
            jsonDetail = JSON.parse(item.targetData.detail);
          } catch (error) {
            jsonDetail = item.targetData.detail;
          }
          if (
            attachments.length == 0 &&
            jsonDetail.length == 1 &&
            jsonDetail[0].key == "text" &&
            !tags
          ) {
            leftData.push(item);
          } else if (
            attachments.length == 0 &&
            jsonDetail.length == 0 &&
            !tags
          ) {
            leftData.push(item);
          } else {
            rightData.push(item);
          }
        } else if (item.targetType == 3) {
          if (
            item.targetData.aboutMe ||
            item.targetData.intro ||
            item.targetData.skills
          ) {
            rightData.push(item);
          } else {
            leftData.push(item);
          }
        } else {
          rightData.push(item);
        }
      });
      try {
        if (leftData.length > 0) {
          for (let i = 0; i < leftData.length; i++) {
            let targetData = leftData[i].targetData;
            if (targetData.reviewerId && !targetData.providerId) {
              targetData.vipStatus = await this.getUserInfo(
                targetData.reviewerId
              );
              targetData.id = targetData.reviewerId;
              targetData.name = targetData.reviewer;
            }
            if (targetData.providerId && !targetData.reviewerId) {
              targetData.vipStatus = await this.getUserInfo(
                targetData.providerId
              );
              targetData.id = targetData.providerId;
            }
          }
        }
        if (rightData.length > 0) {
          for (let i = 0; i < rightData.length; i++) {
            let targetData = rightData[i].targetData;
            if (targetData.providerId) {
              targetData.vipStatus = await this.getUserInfo(
                targetData.providerId
              );
            }
          }
        }
      } catch (error) {}
      this.leftData = leftData;
      this.rightData = rightData;
      // 默认全选
      this.checkedAditList = this.leftData || [];
      this.checkedRightData = this.rightData || [];
      this.checkAll = true;
      this.checkRightAll = true;
      this.isIndeterminate = false;
      this.rightIsIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.leftData.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.leftData.length;
    },
    handleCheckedRightChange(value) {
      let checkedCount = value.length;
      this.checkRightAll = checkedCount === this.rightData.length;
      this.rightIsIndeterminate =
        checkedCount > 0 && checkedCount < this.rightData.length;
    },
    toDetials(data) {
      this.setExamineId(data.id + Date.now());
      this.setExamineData(data);
      this.setExaminePopoverVisible(true);
    },
    // 加载更多
    loadMore() {
      if (this.page >= this.pages) {
        this.isShowLoadMore = false;
        return;
      }
      this.page++;
      this.getAditList();
    },
    // 下载文件
    downloadFile(fileInfo) {
      let downloadFileUrl = `${this.env(
        "BASEURL"
      )}/rest/user/download?downloadUrl=${this.formartImageUrl(
        fileInfo.path
      )}&fileName=${fileInfo.name.replace(/<\/?.+?\/?>/g, "")}`;
      if (fileInfo.name.indexOf("%") != -1) {
        if (fileInfo.name.indexOf("pdf") != -1) {
          window.open(fileInfo.path, "_blank");
        } else {
          var a = document.createElement("a"); //页面上创建一个标签
          a.setAttribute("href", fileInfo.path); //设置a 标签的href，
          a.setAttribute("target", "_blank"); //设置a 标签的download 属性，并限定下载文件名。。
          a.click(); //出发a点击事件，下载文件
          a.remove();
        }
      } else {
        window.open(downloadFileUrl, "_blank");
      }
    },
    async scrollToTop() {
      await this.$nextTick();
      this.$refs.scrollWrap1 && (this.$refs.scrollWrap1.scrollTop = 0);
      this.$refs.scrollWrap2 && (this.$refs.scrollWrap2.scrollTop = 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.imelink-reviewview {
  height: 100%;

  .title {
    font-size: 16px;
    font-weight: 700;
    color: #000000 !important;
  }

  .cont {
    color: #333;
  }

  .refreshExamine {
    padding: 7px 12px;
    position: absolute;
    top: 10px;
    left: 60%;
    transform: translateX(-50%);
    background: #fff2db;
    border-radius: 6px;
    font-size: 14px;
    color: #ff8000;
    z-index: 2022;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep .el-tabs__header {
      background: #fff;
      margin: 0;
    }

    :deep .el-tabs__content {
      flex: 1;
      position: relative;
    }
  }

  :deep .el-tabs__nav-scroll {
    padding-left: 40px;

    span {
      padding: 0 10px;

      i {
        color: #8f8f8f;
        font-size: 25px;
      }
    }

    .is-active {
      span {
        i {
          color: #333333;
        }
      }
    }
  }

  :deep .el-tabs__active-bar {
    background-color: #333333;
  }

  :deep .el-tabs__nav-wrap::after {
    background: #f0f0f0;
  }

  :deep .el-checkbox-group {
    font-size: 14px;
  }

  :deep .el-checkbox__label {
    display: none;
  }

  .item {
    padding: 15px 20px;
    background: #fff;

    align-items: center;
    margin-bottom: 10px;
    .top {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .cont {
      margin-top: 6px;
      padding-left: 40px;
    }
    .image {
      width: 100%;
      height: 100px;
      box-sizing: border-box;
      padding-left: 40px;
      overflow: hidden;
    }

    .el-checkbox {
      margin-right: 20px;

      :deep .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        &::after {
          left: 6px;
          width: 5px;
          height: 10px;
        }
      }
    }

    .head {
      width: 40px;
      height: 40px;
      // background: rebeccapurple;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .xvipName {
      max-width: 130px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      .vipDisplayName {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .vipIcon {
        width: 17px;
        height: 14px;
        margin-left: 6px;
      }
    }

    .name {
      color: #999999;
      font-size: 14px;
      cursor: default;
    }
  }

  //加载更多
  .loadMoreWrap {
    display: flex;
    justify-content: center;
    padding: 15px 0 20px 0;

    .loadMore {
      padding: 8px 24px;
      color: #52bd68;
      font-size: 13px;
      border: 1px solid #52bd68;
      border-radius: 6px;
      width: fit-content;

      cursor: pointer;
    }
  }

  .scroll-wrap {
    overflow-y: auto;
    height: 100%;
    // padding-bottom: 66px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0px;
    }

    :deep .el-checkbox-group {
      // height: 100vh;
      height: 100%;
    }
  }

  .noData {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  .operation {
    height: 66px;
    width: 100%;
    background: #fff;
    position: absolute;
    z-index: 100;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    border-top: 1px solid #e6e6e6;

    .right {
      display: flex;

      .btn {
        width: 124px;
        height: 40px;
        background: #f0f0f0;
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
          color: #4d4d4d;
          font-size: 24px;
        }
      }
    }

    .el-checkbox {
      margin-right: 20px;

      :deep .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        &::after {
          left: 6px;
          width: 5px;
          height: 10px;
        }
      }
    }

    :deep .el-checkbox__input {
      &.is-indeterminate {
        .el-checkbox__inner {
          &::before {
            top: 8px;
          }
        }
      }
    }
  }

  :deep #pane-first {
    height: 100%;
    // padding-bottom: 66px;
  }

  :deep #pane-second {
    height: 100%;

    .cont {
      padding-left: 0px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 这里是超出几行省略 */
      overflow: hidden;
      font-size: 14px;
    }

    .user {
      margin-top: 10px;

      .img {
        width: 110px;
        height: 100px;
        object-fit: cover;
        border-radius: 3px;
      }
    }

    .video {
      width: 110px;
      height: 100px;
      margin-top: 10px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        object-fit: cover;
      }

      .videoStatus {
        .videoStatusIconStyle {
          position: absolute;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 18px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.5);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .scroll-wrap {
      .item {
        cursor: pointer;
        overflow: hidden;
      }
      .rightBoxItem {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .checkbox {
          width: 50px;
          flex-shrink: 0;
        }
        .itemContent {
          flex: 1;
          overflow: hidden;
          .xvipName {
            max-width: 130px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            .vipDisplayName {
              max-width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .vipIcon {
              width: 17px;
              height: 14px;
              margin-left: 6px;
            }
          }
        }
      }
    }

    .att {
      margin-top: 10px;

      .file-box {
        padding: 10px 12px;
        background: #f7f7f7;
        width: fit-content;
        display: flex;
        align-items: center;
        border-radius: 8px;

        .prixImg {
          width: 48px;
          height: 49px;
          // background: #4c98fc;

          img {
            width: 100%;
            height: 100%;
          }

          margin-right: 16px;
        }
      }
    }

    .url {
      display: flex;
      align-items: center;
    }

    ._a {
      width: 100%;
      width: 343px;
      height: 82px;
      background: rgb(247, 247, 247);
      border-radius: 8px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 14px 24px;

      .webImag {
        max-width: 64px;
        // height: 64px;
        margin-right: 25px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .webTitle {
        font-size: 15px;
        font-weight: 700;
        color: #1a051d;
      }

      .webCont {
        width: 100%;
        text-align: left;
        font-size: 12px;
        color: rgb(55, 55, 55);
        margin-top: 9px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .group {
      // padding: 15px 18px;
      display: flex;
      align-items: center;

      .ghead {
        width: 64px;
        height: 64px;
        margin-right: 20px;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .gname {
        color: #333333;
        font-size: 15px;
      }
    }

    .operat {
      width: 20px;
      margin-left: auto;

      i {
        color: #cccccc;
        font-size: 20px;
      }
    }
  }
}

.pd66 {
  padding-bottom: 66px;
}
</style>
